export const patientHome: any = {
    patHomeMainMenu: "Main Navigational Menu Consumer Retina Brazil (PT)",
    patHomeFooterMenu: "Footer Navigation Menu Consumer Retina Brazil (PT)",
    patHomeCarousel: "Homepage Carousal - Brazil (PT)",
    patFooter: "Footer - Consumer - Retina Brazil (PT)",
    patExitPopup: "Exit Popup Brazil (PT)",
    patHCPSwitcher: "HCP Switcher Modal Pop Up - Retina Brazil (PT)",
    patTopNav: "Top Navigation Menu Retina Brazil (PT)",
    patVideo1: "Home Brightcove Video Section Retina Brazil (PT)",
    patInfographic: "Infographic Percentage Home Consumer Brazil (PT)",
    patFootrCallout: "Footer Callout Home - HTML DTC Retina Brazil (PT)",
    patSiteLogo: "Site logos - Retina Brazil (PT)",
    patFooterRef: "DTC - Home page - Reference - Brazil (PT)",
    socialBlock: "Footer Social Icons",
    carousalAttrs: {
      playBtnGTM: {
        'className': 'gtm-link-internal',
        'data-gtm-event-label': 'hero image - play',
        'aria-label': 'Início diapositivas'
      },
      pauseBtnGTM: {
        'className': 'gtm-link-internal',
        'data-gtm-event-label': 'hero image - pause',
        'aria-label': 'Pausar diapositivas'
      },
      btnTexts: {
        'play': 'INICIAR',
        'pause': 'PAUSAR'
      },
      playBtnAriaDescription: ' ',
      pauseBtnAriaDescription: ' '
    },
    video1Attrs: {
      viewTranscriptGTMLabels: [
        {
          "data-gtm-event-label": "genetic testing story of cesar - view transcript",
          "className": "gtm-accordion",
          "aria-label": "Mostrar transcrição para A busca de César pelo seu diagnóstico genético"
        }
      ],
      hideTranscriptGTMLabels: [
        {
          "data-gtm-event-label": "genetic testing story of cesar - hide transcript",
          "className": "gtm-accordion",
          "aria-label": "Mostrar transcrição para A busca de César pelo seu diagnóstico genético"
        }
      ]
    },
    backToTopGTM: "back to top - homepage",
    footerClassName: "home-footer"
  }
